import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styled from "@emotion/styled";
import { navigate } from "gatsby";
import { css } from "@emotion/core";
import { theme } from "../../../../utils/global-styles/theme";
import DownArrowIcon from "../../DownArrowIcon";
import Markdown from "markdown-to-jsx";

const DropdownSelect = ({
  handleChange,
  label,
  name,
  placeholder,
  required,
  styles,
  values,
  key,
  errorFromParent,
  downArrowIconClassName,
  placeholderIcon,
  fullscreenOnMobile,
  isMobile,
  labelClassNames,
  disabled,
  footerLink,
  toggleOpenState,
  open,
  fellowshipLanderDropdownHeading,
}) => {
  const [activeOption, setActiveOption] = useState(null),
    handleDropDownToggle = openState => {
      toggleOpenState(openState);
    },
    SelectWrapper = Styled.button`
      background-color: transparent;
      border: none;
      position: relative;
      &:focus {
        outline: none;
      }
    ${styles && styles.wrapperStyles ? styles.wrapperStyles : ""}`,
    StyledLabel = Styled.label`
      ${styles && styles.labelStyles ? styles.labelStyles : ""}
    `,
    SelectInnerWrapper = Styled.div`
      &:hover {
        cursor: ${disabled ? "not-allowed" : "pointer"};
      }
      ${styles && styles.selectStyles ? styles.selectStyles : ""}
    `,
    PlaceholderContainer = Styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: ${theme.colors.white};
      ${
        styles && styles.placeholderWrapperStyles
          ? styles.placeholderWrapperStyles
          : ""
      }
      ${isMobile && fullscreenOnMobile && open ? "display: none;" : ""}
    `,
    PlaceholderIconAndTitle = Styled.span`
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    PlaceholderText = Styled.p`
      font-size: 26px;
      font-weight: bold;
      letter-spacing: -0.9px;
      line-height: 60px;
      margin-left: 16px;
      font-family: ${theme.fontFaces.agStandardBold};
      ${styles && styles.placeholderStyles ? styles.placeholderStyles : ""}
    `,
    OptionsList = Styled.ul`
      z-index: 3;
      ${
        isMobile && fullscreenOnMobile
          ? `
        position: fixed;
        left: 0;
        overflow-x: hidden;
        top: 60px;
        height: calc(100vh - 60px);
        padding-top: 53px;
        `
          : `
        position: absolute;
        width: 98.6%;
        `
      }
      transform-origin: top;
      ${
        !isMobile
          ? `
      -webkit-animation: dropdown .3s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: dropdown .3s ease-in-out; /* Firefox < 16 */
      -ms-animation: dropdown .3s ease-in-out; /* Internet Explorer */
      -o-animation: dropdown .3s ease-in-out; /* Opera < 12.1 */
      animation: dropdown .3s ease-in-out;
      `
          : ``
      }
      ${styles && styles.listStyles ? styles.listStyles : ""}
    `,
    ListFooter = Styled.li`
    list-style-type: none;
    &:hover {
      cursor: pointer;
    }
    ${styles && styles.listFooterStyles ? styles.listFooterStyles : ""}
    
  `,
    ListOption = Styled.li`
      list-style-type: none;
      &:hover {
        cursor: pointer;
      }
      ${styles && styles.itemStyles ? styles.itemStyles : ""}
      ${isMobile &&
        fullscreenOnMobile &&
        `height: calc((100vh - 60px) / ${values.length + 1});`}
    `,
    CancelListOption = Styled.li`
      list-style-type: none;
      background-color: ${theme.colors.light};
      font-family: ${theme.fontFaces.agStandardBold};
      padding: 0 15px;
      &:hover {
        cursor: pointer;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      ${`height: calc((100vh - 60px) / ${values.length + 1});`}
    `,
    CancelText = Styled.span`
      font-size: 13px;
      font-weight: bold;
      letter-spacing: -0.28px;
      line-height: 16px;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      height: fit-content;
      align-self: flex-end;
      padding: 0;
    `,
    InnerContainer = Styled.div`
      display: flex;
      margin-bottom: 20px;
    `;

  /**
   * Use the hook on the open property to control whether we disallow
   * scrolling. We do if the props passed in from the caller specify
   * the background shouldn't scroll. -RJH 2/20
   */
  useEffect(() => {
    if (fullscreenOnMobile && isMobile) {
      const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop,
        scrollLeft = window.pageXOPffset || document.documentElement.scrollLeft;
      return (window.onscroll = open
        ? () => window.scrollTo(scrollLeft, scrollTop)
        : function() {});
    }
    return;
  }, [open]);

  return (
    <SelectWrapper
      key={key || "0"}
      onClick={e => {
        e.preventDefault();
        if (!disabled) {
          handleDropDownToggle(open);
        }
      }}
      className="dynamic-form-input-wrapper"
    >
      <StyledLabel className={labelClassNames}>{label}</StyledLabel>

      <SelectInnerWrapper
        name={name}
        required={required || false}
        disabled={disabled || false}
      >
        <PlaceholderContainer>
          <PlaceholderIconAndTitle>
            {placeholderIcon && placeholderIcon}
            <PlaceholderText>
              {activeOption || fellowshipLanderDropdownHeading || "Choose One"}
            </PlaceholderText>
          </PlaceholderIconAndTitle>
          <DownArrowIcon
            width="18px"
            height="18px"
            classNames={
              downArrowIconClassName
                ? downArrowIconClassName
                : "path-primary-fill"
            }
            rotateValue={open ? "180" : "0"}
          />
        </PlaceholderContainer>
        <OptionsList aria-expanded={open} role="list">
          {open &&
            !disabled &&
            values.map((value, i) => {
              if (value.displayName) {
                return (
                  <ListOption
                    onClick={() => {
                      setActiveOption(value.displayName);
                      handleChange(value);
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter" || e.key === " ") {
                        setActiveOption(value.displayName);
                        handleChange(value);
                        handleDropDownToggle(false);
                        // handleDropDownStateChanged(false);
                      }
                    }}
                    key={i}
                    className={`${
                      i === values.length - 1
                        ? ""
                        : "secondary-background-hover"
                    }`}
                    tabIndex="0"
                    id={`option-${i}`}
                  >
                    {value.displayName}
                    {value.message && (
                      <>
                        {typeof value.message !== "object" ? (
                          <p
                            className={
                              value.active ? "secondary" : "disabled-text"
                            }
                            css={css`
                              ${styles && styles.itemMessageStyles
                                ? styles.itemMessageStyles
                                : ""}
                            `}
                          >
                            {value.message ? value.message.toUpperCase() : null}
                          </p>
                        ) : (
                          <p>{value.message}</p>
                        )}
                      </>
                    )}
                  </ListOption>
                );
              }
              return null;
            })}
          {open && (
            <ListFooter>
              <p>
                {
                  <Markdown
                    options={{
                      overrides: {
                        a: {
                          props: {
                            className: "primary",
                          },
                        },
                      },
                    }}
                    onClick={e => {
                      e.preventDefault();
                      navigate(`${footerLink.url}/`);
                    }}
                  >
                    {footerLink.title}
                  </Markdown>
                }
              </p>
            </ListFooter>
          )}
          {isMobile && fullscreenOnMobile && open && (
            <CancelListOption>
              <CancelText className={"primary primary-border"}>
                CANCEL
              </CancelText>
            </CancelListOption>
          )}
        </OptionsList>
      </SelectInnerWrapper>
      {errorFromParent}
    </SelectWrapper>
  );
};

DropdownSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  open: PropTypes.bool,
  toggleOpenState: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.key,
      displayName: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  fullscreenOnMobile: PropTypes.bool,
  isMobile: PropTypes.bool,
  placeholder: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  styles: PropTypes.shape({
    labelStyles: PropTypes.string,
    valueStyles: PropTypes.string,
    wrapperStyles: PropTypes.string,
    listFooterStyles: PropTypes.string,
    placeholderStyles: PropTypes.string,
    placeholderWrapperStyles: PropTypes.string,
    listStyles: PropTypes.string,
    itemStyles: PropTypes.string,
    itemMessageStyles: PropTypes.string,
  }),
  downArrowIconClassName: PropTypes.string,
  placeholderIcon: PropTypes.element,
  labelClassNames: PropTypes.string,
  footerLink: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default DropdownSelect;
